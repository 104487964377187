import React from "react";
import Container from "../container";
import { variables as vars } from "../../utils/variables";
import "./styles.css";

const VideButton = (props) => {
  const verTudo = () => {
    window.open(vars("youtubeLink"), "_blank");
  };
  
  const sendEvent = () => {
    // console.log(section)
    props.gaEvent(
      "YT",
      "Click en Video",
      "Video",
    );
  };

  return (
    <Container>
      <div className="row video-container">
        <div className="col py-5 text-center">
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src={vars("youtubeIframe")}
              allowFullScreen 
              onClick={sendEvent}
            ></iframe>
          </div>
          <h4 className="font-bold mt-4 mb-0 text-uppercase">
            {vars("video1")}
            <br />
            {vars("video2")}
          </h4>
          <h4>{vars("video3")}</h4>
          <button className="btn" onClick={verTudo}>
          {vars("video4")}
          </button>
        </div>
      </div>
    </Container>
  );
};

export default VideButton;
