import React from "react";
import * as Scroll from "react-scroll";
import { animateScroll as scroll } from "react-scroll";
import ReactGA from "react-ga";
import "./App.css";
import HeaderAndNav from "./components/headerAndNav";
import BannerMain from "./components/bannerMain";
import BannerSecondary from "./components/bannerSecondary";
import BannerText from "./components/bannerText";
import BannerTecnologia from "./components/bannerTecnologia";
import Carousel from "./components/carousel";
import VideoButton from "./components/videoButton";
import Footer from "./components/footer";
import { useState } from "react";
import { getTypes } from "./services/fakePaintingTypeService";
import { getPaintings } from "./services/fakePaintingService";
import { useEffect } from "react";
import { variables as vars } from "./utils/variables";

function App() {
  const [paintingTypes, setPaintingTypes] = useState([]);
  const [selectedPaintingType, setSelectedPaintingType] = useState(null);
  const [paintings, setPaintings] = useState([]);

  const fetchData = async () => {
    const types = await getTypes();
    setSelectedPaintingType(types[0]);
    // console.log(types)
    setPaintingTypes(types);
    // console.log(selectedPaintingType)
    const paints = await getPaintings(selectedPaintingType);
    setPaintings(paints);
    // console.log(types, paints)
  };

  useEffect(() => {

    if (vars("countryAbbr") == "br") {  

      ReactGA.initialize(
        [
          {
            trackingId: vars("gaId"),
            gaOptions: {
              name: "tracker1",
            },
          },
        ],
        { debug: false }
      );

      ReactGA.ga('create', vars("gaId2"), 'auto', {'name':'tracker2'});

    } else {
      ReactGA.initialize(
        [
          {
            trackingId: vars("gaId"),
            gaOptions: {
              name: "tracker1",
            },
          },
        ]
      );
    }
    fetchData();
  }, []);

  const cookiesScript = vars("cookiesScript");

  const gaEvent = (category, action, label) => {
    ReactGA.ga("tracker1.send", "event", {'eventCategory':category, 'eventAction':action, 'eventLabel':label} );
    if (vars("countryAbbr") == "br") {
      ReactGA.ga("tracker2.send", "event", {'eventCategory':category, 'eventAction':action, 'eventLabel':label} );
    }

    return null;
  };

  const goTo = (id) => {
    const element = document.getElementById(id);
    const pos = element.offsetTop - 80;
    scroll.scrollTo(pos);
  };

  const changeSelectedType = async (type) => {
    setSelectedPaintingType(type);
    const paintings = await getPaintings(type.id);
    // console.log(paintings)
    setPaintings(paintings);
    // console.log(paintings);
    goTo("carousel");
  };

  return (
    <div className="container-fluid">

      {paintingTypes && (
        <HeaderAndNav
          paintingTypes={paintingTypes}
          selectedPaintingType={selectedPaintingType}
          selectType={changeSelectedType}
          gaEvent={gaEvent}
        />
      )}
      <BannerMain />
      <BannerSecondary
        paintingTypes={paintingTypes}
        selectType={changeSelectedType}
        goTo={goTo}
        gaEvent={gaEvent}
      />
      <BannerText />
      <BannerTecnologia />
      {paintings && (
        <Carousel
          paintings={paintings}
          selectedPaintingType={selectedPaintingType}
          gaEvent={gaEvent}
        />
      )}
      <VideoButton gaEvent={gaEvent} />
      <Footer />
    </div>
  );
}

export default App;
