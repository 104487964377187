export const variables = {
    countryAbbr: "br",
    gaId:"UA-10814972-3",
    gaId2: "UA-141508909-3",
    mainBannerHref: "https://www.lojacoral.com.br/sparlack-cetol-durabilidade", 
    tutorialsValue: "Tutoriais",
    tutorialsHref: "https://www.youtube.com/user/SparlackOficial/",
    tutorialsEvent: "Tutoriais",
    cetolTecaHref: "https://sparlack.com.br/cetolteca/",
    storesValue: "Lojas",
    storesHref: "https://www.coral.com.br/pt/encontre-uma-loja",
    storesEvent: "Lojas",
    showInspiration: true,
    inspiration: "Inspiraçao",
    inspirationHref: "https://www.pinterest.com/sparlack/?eq=Sparlack&etslf=5178",
    inspirationEvent: 'Inspiraçao',
    theCompany: "A empresa",
    colors: "Cores",
    showColors: true,
    colorsHref: "/pdfs/br/cores.pdf",
    colorsEvent: "Cores",
    soon: "EM BREVE",
    youWillBeAble: "você poderá conhecer as melhores ideias",
    ofInspiration: "de inspiraçao",
    goBack: "voltar a home",
    fbk: "https://www.facebook.com/Sparlack/",
    ig: "https://www.instagram.com/sparlack/",
    email: 'especialista.coral@concentrix.com',
    phone: '08000117711',
    phoneText: 'SAC 0800 011 7711',
    faq: "FAQ",
    faqLink: "FAQ Sparlack 2021.pdf",
    laudoLink:"https://www.sparlack.com.br/tecnica/",
    laudo:"Laudo",

    woodLives: "A MADEIRA VIVE,",
    weTakeCare: "NÓS CUIDAMOS DELA",
    text1: "A madeira vive, tem movimento... Dilata, contrai e respira.",
    text2: `Como nosso corpo, sem uma pele que gera uma resposta adequada ao
    meio ambiente, a madeira fica desprotegida de agentes externos e
    condenada ao envelhecimento prematuro.`,
    text3: `Uma vez que separamos a madeira da natureza, ela perde a
    proteção natural que apenas Sparlack Cetol pode devolver.`,
    text4: `Se substituíssemos a palavra pele por Sparlack Cetol, teríamos
    uma descrição perfeita do nosso produto.`,
    text5: `A PELE QUE A SUA MADEIRA PRECISA`,
    text6: `A nova linha de protetores Sparlack Cetol possui uma tecnologia
    microporosa que cria uma segunda pele na madeira para proteger e
    cuidar. Uma película elástica microporosa que não trinca, não
    descasca e acompanha os movimentos naturais da madeira.`,
    text7: `Máxima Durabilidade`,
    text8: `Maior resistência`,
    text9: `Fornece proteção duradoura para suas madeiras e resistência
    superior às condições climáticas.`,
    tecno1: `Nossos produtos também possuem`,
    tecno2: `TECNOLOGIA BALANCE`,
    tecno3: `Tecnologia que oferece a mesma durabilidade e`,
    tecno4: `resistência do protetor e
    verniz tradicional,`,
    tecno5: `combinada com maior
    praticidade e conveniência.`,
    tecno6: `Secagem mais rápida`,
    tecno7: `Sem cheiro`,
    tecno8: `Pronto para usar`,
    video1: `Acesse nossos`,
    video2: `conteúdos exclusivos`,
    video3: `NO YOUTUBE`,
    video4: `Ver tudo`,
    youtubeLink: "https://www.youtube.com/user/SparlackOficial",
    youtubeIframe: "https://www.youtube.com/embed/tTqAPeCFqFU?rel=0",
    card1: `Cores`,
    card2: `Acabamento`,
    card3: `Indicações`,
    card4: `Secagem`,
    card5: `Embalagens`,
    card6: `Baixar BT`,
    card7: `Baixar FISPQ`

}
