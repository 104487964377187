import React, { useState, useEffect } from "react";
import Container from "../container";
import logo from "../../img/logo2.png";
import logoMobile from "../../img/logo-mobile.png";
import hamburguer from "../../img/hamburguer.png";
import "./styles.css";
import Social from "../social";
import { variables as vars } from "../../utils/variables";

const HeaderAndNav = (props) => {
  const { paintingTypes, selectedPaintingType, selectType } = props;
  const [isAtTheTop, setIsAtTheTop] = useState(true);

  window.addEventListener(
    "scroll",
    function () {
      // console.log(window.pageYOffset)
      if (window.innerWidth > 767 && window.pageYOffset > 35 && isAtTheTop)
        setIsAtTheTop(false);
      if (window.innerWidth > 767 && window.pageYOffset === 0 && !isAtTheTop)
        setIsAtTheTop(true);
    },
    false
  );

  const sendEvent = (section) => {
    console.log(section)
    props.gaEvent("Categoría", "Click en " + section, section);
  };

  return (
    <header className={"row bg" + (!isAtTheTop ? " shrink" : "")}>
      <div className="col">
        <Container>
          {/* INIT NAV  */}
          <nav
            className={
              "navbar navbar-expand-lg " + (!isAtTheTop ? " shrink" : "")
            }
          >
            <a
              className={"navbar-brand" + (!isAtTheTop ? " shrink" : "")}
              href="#"
            >
              <img
                src={logo}
                alt="Logo"
                id="logo-desktop"
                className={
                  "d-none d-md-block logo " + (!isAtTheTop ? " shrink" : "")
                }
              />
              <img src={logoMobile} alt="Logo" className="d-md-none logo " />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {/* <span className="navbar-toggler-icon"></span> */}
              <img src={hamburguer} alt="hamburguer" id="hamburguer" />
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul
                className={
                  "navbar-nav ml-auto" + (!isAtTheTop ? " shrink" : "")
                }
              >
                {paintingTypes.map((type) => (
                  <li
                    key={type.id}
                    className={
                      "nav-item" +
                      (type.name === selectedPaintingType ? " active" : "")
                    }
                    onClick={() => sendEvent(type.name)}
                  >
                    <a
                      className={
                        "nav-link" +
                        (type.id === 1 ? " font-bold" : " d-md-none")
                      }
                      onClick={() => selectType(type)}
                    >
                      {type.name}
                      {type.name === selectedPaintingType && (
                        <span className="sr-only">(current)</span>
                      )}
                    </a>
                  </li>
                ))}

                <li
                  className="nav-item"
                  onClick={() => sendEvent(vars("tutorialsEvent"))}
                >
                  <a
                    className="nav-link font-bold text-uppercase"
                    href={vars("tutorialsHref")}
                    target="_blank"
                  >
                    {vars("tutorialsValue")}
                  </a>
                </li>

                {vars("tiendasHref") ? 
                  <li className="nav-item" 
                      onClick={() => sendEvent(vars("tiendasEvent"))}
                  >
                    <a className="nav-link font-bold text-uppercase" href={vars("tiendasHref")} target="_blank">{vars("tiendasValue")}</a></li> : ""}
                
 {/*               
                <li
                  className="nav-item"
                  onClick={() => sendEvent(vars("storesEvent"))}
                >
                  <a
                    className="nav-link font-bold"
                    href={vars("storesHref")}
                    target="_blank"
                  >
                    {vars("storesValue")}
                  </a>
                </li>
*/}
                {vars("showInspiration") && (
                  <li className="nav-item">
                    <a
                      className="nav-link font-bold"
                      href={vars("inspirationHref")}
                      target="_blank"
                    >
                      {vars("inspiration")}
                    </a>
                  </li>
                )}
                {vars("showColors") && (
                  <li 
                    className="nav-item"
                    onClick={() => sendEvent(vars("colorsEvent"))}
                  >
                    <a
                      className="nav-link font-bold"
                      href={vars("colorsHref")}
                      target="_blank"
                    >
                      {vars("colors")}
                    </a>
                  </li>
                )}


{/*
                <li className="nav-item d-md-none">
                  <a
                    className="nav-link font-bold"
                    href="https://sparlack.com.br/empresa"
                    target="_blank"
                  >
                    {vars("theCompany")}
                  </a>
                </li>

                <li className="nav-item d-md-none">
                  <a
                    className="nav-link font-bold nb"
                    href="https://sparlack.com.br/cores/sistema-tintometrico"
                    target="_blank"
                  >
                    {vars("colors")}
                  </a>
                </li>
*/}
                <li className="nav-item d-md-none liredes text-center">
                  <Social />
                </li>
              </ul>
            </div>
          </nav>
          {/* END NAV */}
        </Container>
      </div>
      <div
        className="modal fade my-modal"
        id="inspiModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="inspiModal"
        aria-hidden="true"
      >
        <div
          className="d-flex justify-content-center align-items-center h-100"
          data-dismiss="modal"
          aria-label="Close"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body text-center">
                <h3>{vars("soon")}</h3>
                <p>
                  {vars("youWillBeAble")}
                  <br /> {vars("ofInspiration")}
                </p>
                <a>{vars("goBack")}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderAndNav;
