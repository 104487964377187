import React from "react";
import "./styles.css";
import Container from "../container";
import azkoLogo from "../../img/azko_logo.png";
import Social from "../social";
import { variables as vars } from "../../utils/variables";

const Footer = () => {
  return (
    <div className="row wood-bg">
      <div className="col">
        <Container>
          <footer className="row py-md-5 py-3 px-3 px-md-1 ">
            <div className="col-12 col-md-2">
              <Social />
            </div>
            <div className="col-12 col-md-8 pt-2">
              <div className="row">
                <div className="col-md-auto text-center text-md-start">
                  <a href={"mailto:" + vars("email")} target="_blank">
                    {vars("email")}
                  </a>
                </div>
                {vars("phone") && (
                  <div className="col-md-auto text-center text-md-start">
                    <a href={"tel:" + vars("phone")} target="_blank">
                      {vars("phoneText")}
                    </a>
                  </div>
                )}
                <div className="col-md-auto  text-center text-md-start">
                  <a href={vars("faqLink")? process.env.PUBLIC_URL + "/pdfs/"+vars("countryAbbr")+"/" +vars("faqLink") : ""} target="_blank">
                    {vars("faq")}
                  </a>
                </div>
                {vars("laudo") && (
                <div className="col-md-auto  text-center text-md-start">
                  <a href={vars("laudoLink")} target="_blank">
                    {vars("laudo")}
                  </a>
                </div>   
                )}            
              </div>
            </div>
            <div className="col-md-2 col-8 offset-2 offset-md-0 text-center text-md-right pt-2">
              <img
                className="d-none d-md-block azko-logo"
                src={azkoLogo}
                alt="AkzoNobel"
              />
              <img
                className="d-md-none azko-logo img-fluid"
                src={azkoLogo}
                alt="AkzoNobel"
              />
            </div>
          </footer>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
